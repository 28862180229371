.container{
    padding-top: 10vh;
    min-width: 100vw;
    min-height: 90vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(216, 230, 243);
}
.sectionDevider{
    background-color: rgb(167, 178, 189);
    height: 250px;
    width: 100vw;
    margin-top: 100px;
}
.title{
    width: 50%;
    text-align: center;
    font-size: 66px;
    font-weight: 700;
    line-height: 1.5em;
    white-space: pre-wrap;
}
.loginButtonContainer{
    display: flex;
    justify-content: center;
    gap: 5%;
    width: 30%;
    z-index: 1000;
}
.loginButtonContainer button{
    cursor: pointer;
    border: none;
    font-size: 1.2em;
    padding: 1em;
    color: white;
    border-radius: 7px;
    background-color: rgb(161, 197, 255);
    transition-property: transform box-shadow;
    transition-duration: 0.3s;
}
.loginButtonContainer button:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
.section1, .section2{
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section1h1{
    font-size: 55px;
    transition: all 1s ease;

}
.section1Text1{
    font-size: 20px;
    transition: all 0.4s ease;
    padding-bottom: 30px;
}
.section1List{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.section1List div{
    box-shadow: 0px 5px 10px 1px rgb(102, 126, 165);
    background: linear-gradient(
        135deg, 
        rgb(175, 211, 253), 
        rgb(135, 187, 245), 
        rgb(175, 176, 253)
      );
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    width: 30%;
    color: white;
    font-size: 1.6em;
    height: 200px;
}
.img{
    display: flex;
    justify-content: center;
    transition: all 2s ease;
}

.section2TextBox1{
    color: white;
    margin-top: 3%;
    height: 20vh;
    width: 100vw;
    background: linear-gradient(
        135deg, 
        rgb(175, 211, 253), 
        rgb(135, 187, 245), 
        rgb(175, 176, 253)
      );
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
}
.section2TextBox1 div{
    transition: transform 1.5s ease-in;
}
.section3{
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .title{
        width: 80%;
        font-size: 33px;
    }
    .subTitle{
        font-size: 22px;
    }
    .loginButtonContainer button{
        font-size: 0.8em;
        padding: 0.7em;
    }
    .section1List{
        width: 100%;
    }
    .section1List div{
        width: 30%;
        font-size: 0.7em;
        height: 50px;
    }
    .sectionDevider{
        height: 150px;
    }
    .section1, .section2{
        margin-top: 100px;
    }
    .section2TextBox1{
        height: 15vh;
        margin-top: 10%;
        font-size: 1.2em;
    }
}