.fileContainer{
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100px;
    border-radius: 7px;
    cursor: pointer;
}
.fileContainer:hover{
    background-color: rgb(217, 228, 238);
}