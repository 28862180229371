.faBackword {
    display: flex;
    align-self: baseline;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.back-button{
    margin-left: 8px;
    transition: scale 0.2s ease;
}
.back-button:hover{
    scale: 1.05;
}
.mypage-profile-image{

    border-radius: 50%;  /* 원형으로 만들기 */
    overflow: hidden;    /* 원 밖의 이미지 잘라내기 */
    display: flex;
    justify-content: center;
    align-items: center; 
    transition: scale 0.2s ease;           
}
.mypage-profile-image:hover{
    scale: 1.05;
}
.mypage-profile-image img {
    width: 100%;          /* 이미지가 컨테이너를 가득 채우도록 */
    height: 100%;         /* 이미지가 컨테이너를 가득 채우도록 */
    object-fit: cover; 
}