@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
body {
  margin: 0;
}
.root{
  font-family: 'Pretendard-Regular';
  display: flex;
  background-color: aliceblue;
}
/* 스크롤바 전체 영역 */
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, rgb(192, 216, 255), rgb(139, 184, 255));
  border-radius: 10px;
  border: 2px solid #f0f0f0;
}
::-webkit-scrollbar-corner {
  background: #f0f0f0;
}
@media screen and (max-width: 768px) {
  .ReactModal__Content--after-open{
    width: 80vw!important;
  }
}




