.container{
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 70vw;
    overflow: auto;
    white-space:nowrap;
}
.searchConatainer{
    display: flex;
    align-items: center;
    gap:10px;
}
.container button {
    cursor: pointer;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease;
    text-align: center;
}
.container button:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
.container input{
    height: 30px;
    padding-left: 10px;
    border-color: #bdbdbd;
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    width: 200px;
    transition: scale 0.3s ease;
}
.container input:focus{
    outline: none;
    scale: 1.03;
}
.container table{
    border-collapse: collapse;
    margin-top: 5vh;
    width: 100%;
}
.container table thead{
    background-color: rgb(148, 192, 255);
    color: white;
}
.container table thead tr th{
    padding: 10px;
}
.container table tbody tr td{
    padding: 10px;
    border-bottom: solid 1px;
}
@media screen and (max-width: 768px) {
    .container{
        padding-left: 0px;
        font-size: 0.8em;
        align-items: center;
    }
    .container button{
        padding: 0px;
        padding-top: 1vh;
        padding-bottom: 1vh;
        width: 100%;
    }
    .container h1 {
        display: none;
    }
    .container button{
        font-size: 0.5em;
    }
    .container table{
        min-width: 100vw;
        max-width: 100vw;
    }
    .container table thead tr th{
        padding: 0px;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    /* .container table > thead > tr > th:nth-child(1){
    } */
}