.container{
    width: 100vw;
    min-height: 100vh;
    overflow: auto;
}
.header{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* main과 맞출 것! */
    height: 5vh;    
    background-color: rgb(192, 216, 255);
    color: white;
}
.main{
    /* header와 맞출 것! */
    min-height: 95vh;
}


  

.userButton{
    justify-self: flex-end;
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.fileIcon{
    width: 64px;
    height: 64px;
}