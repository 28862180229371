.container {
    background-color: aliceblue;
    padding-top: 3vh;
    padding-left: 100px;
    width: 100%;
    height: 97vh;
    overflow: auto;

}

.container h2{
    margin-bottom: 2rem;
}

/* itemBoard.css */
.forum {
    padding-left: 8rem;
    border-left: 4px solid rgb(102, 161, 255);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.item {
  animation: fadeIn 0.5s ease-in-out;
}
  

  .item {
    position: relative;
    width: 40%;
    min-width: 400px;
    margin: 2.5rem 0;
    padding: 1.5rem 2rem;
    background-color: rgb(219, 233, 255);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }

  /* 원 */
  .item::before {
    content: '';
    position: absolute;
    left: -7px;
    top: -3px;
    width: 15px;
    height: 15px;
    background-color: #007bff;
    background-color: rgb(102, 161, 255);
    border-radius: 50%;
}

.item:hover{
  box-shadow: 2px 2px 10px 5px rgba(146, 185, 248, 0.513);
  transform: translateY(-6%);
  cursor: pointer;

}

.item .date{
    color: rgb(127, 176, 255);
}

  
.itemContent {
    margin-top: 1rem;
}

.itemContent p{
    margin-left: 0.3rem;
    
}



  .itemWrap {
    font-size: 14px;
    color: #555;
    display: flex;
    justify-content: flex-end;
  }

  .author {
    font-weight: bold;
    color: rgb(127, 176, 255);
    font-size: 16px;
  }

  .stick{
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  
  .views {
    color: #888;
    text-align: left;
  }
  

.loader{
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .container{
    padding-left: 0;
    width: 100vw;
  }
  .container h2{
    display: none;
  }
  .forum {
    padding-left: 1rem;
  }
  .item {
    min-width: 85vw;
    margin: 1.5rem 0;
    padding: 1rem;
  }
  .item::before {
    width: 7px;
    height: 7px;
  }
  

}

