.fileIcon{
    width: 32px;
    height: 32px;
    align-self: center;
}

.object{
    display: flex;
    flex-direction: column;
    border-bottom: none;
    padding: 8px;
    position: relative;
    padding-top: 13px;
    padding-bottom: 13px;
}

.object::before {
    content: "";
    position: absolute;
    bottom: 0; /* 요소의 아래쪽에 경계선 배치 */
    left: 47px;
    width: calc(100% - 63px);
    width: 87vw;
    height: 0.8px; /* 경계선 두께 */
    background-color: rgb(159, 159, 159); /* 경계선 색상 */
    /* z-index: 1;  */
}

.sort{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.dateAndSizeSort{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75vw;
}

.titleSeparator{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.titleStyle{
    font-size: 17px;
}

.timeStyle{
    font-size: 14px;
    color: rgb(118, 118, 118);
}

.sizeStyle{
    font-size: 14px;
    font-weight: bold;
    color: rgb(72, 72, 72);
}