
.login-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

  .login-input-container {

    align-self: center;
    justify-self: center;
    background-color: white;
    width: 450px;
    height: 500px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-input {
    display: flex;
    flex-direction: column;
  }
  .login-input-text {
    color: #282c34;
  
    margin-bottom: 15px;
    margin-top: -20px;
    font-size: 32px;
    font-weight: bold;
  }
  .login-id, .login-pw, .join-nickname {
    background-color:rgb(231, 231, 231);
  
    font-size: 15px;
    width: 300px;
    height: 40px;
    margin-top: 15px;
    padding-left: 15px;
    border: none;
    border-radius: 5px;
  }
  .login-input :focus {
    outline: none;
  }
  .remeber-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .remeber-container input {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
  .remeber-container div {
    font-size: 13px;
    color: black;
  }
  .login-button, .join-button{
    background-color: #282c34;
    color: rgb(241, 241, 241);
  
    font-size: 19px;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    transition: font-size 0.2s ease;
  }
  
  .login-button:hover, .join-button:hover {
    font-size: 20px;
  }
  .login-server-message {
    color: rgb(255, 46, 46);
    margin-top: 10px;
    font-size: 12px;
  }

  .login-tutorial{
    margin-top: 10px;
    width: 20%;
    white-space: nowrap;
    text-decoration: underline;
    color: rgb(94, 94, 253);
    cursor: pointer;
    border: none;
    background: transparent;
  }
  @media screen and (max-width: 768px) {
    .login-input-container {
      width: 90vw;
      height: 50vh;
    }
    .login-id, .login-pw, .join-nickname{
      width: 70vw;
      color: #868686;
    }
  }