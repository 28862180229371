.forumCreate{
    background-color: aliceblue;
    width: 100%;
    margin-right: 10rem;
    margin-left: 10rem;
    transition: all 0.8s ease-out;
    min-height: 100vh;
}



@keyframes move-clouds {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}


.cloud {
    background: url('/public/Pink Cloud, Pink ink clouds, pink clouds transparent background PNG clip_Edited.png') no-repeat;
    background-size: contain;
    /* width: 150px; */
    height: 100px;
    animation: move-clouds 25s linear infinite;
    opacity: 0.5;
}


.itemTitle{
    color: rgb(127, 176, 255);
    padding-bottom: 10px;
}

.explain{
    font-size: 15px;
    color: #393939;
    margin-bottom: 10px;
}

.letterLength{
    display: flex;
    justify-content: space-between;
    /* text-align: right; */
    width: 81%;
    margin-top: 5px;
    font-size: 13px;
    color: rgba(127, 176, 255, 0.657);
}

.forumCreate input{
    width: 80%;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    padding: 0.5em 0.5em;
    outline-color: transparent;
    transition: outline-color 0.3s ease;
}

.forumCreate input:focus {
    outline-color: rgb(161, 197, 255);
}
.fileContainer{
    width: 40%;
    max-height: 10vh;
    display: flex;
    justify-content: space-between;
}
.files{
    border-radius: 10px;
    padding-left: 2%;
    padding-right: 2%;
    overflow-y: scroll;
    width: 80%;
    max-height: 100%;
    background-color: rgb(224, 236, 255);
}
.fileText{
    white-space: nowrap;
}
.files button{
    border: none;
    transition: all 0.3s ease;
    background-color: transparent;
}
.file{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.containerHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(127, 176, 255);
}

.submitBtn{
    padding: 0.5rem 1.5rem;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: rgba(161, 197, 255);
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
    letter-spacing: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.forumCreate button:hover{
    
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);

}


.fileUploadBtn{
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-align: center;
}


  .fileUploadBtn:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
  }
  @media screen and (max-width: 768px) {
    .forumCreate{
        margin-right: 0;
        margin-left: 0;
        padding-top: 5vh;
        padding-bottom: 0;
        min-height: 100vh;
        overflow-x: hidden;
    }
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2em;
        margin-left: 0;
        margin-right: 0;
    }
    .pageTitle{
        display: none;
    }
    .forumCreate input{
        width: 90vw;
    }
    .itemTitle, .explain{
        padding-left: 4vw;
        align-self: flex-start;
    }
    .submitBtn{
        z-index: 1000;
        position: fixed;
        bottom: 1vh;
        left: 50vw;
        transform: translateX(-50%);
    }
    .fileContainer{
        width: 100%;
    }
    .files{
        overflow-y: scroll;
        width: 100%;
        max-height: 100%;
    }
    .fileText{

    }
    .files button{
        border: none;
        transition: all 0.3s ease;
        background-color: transparent;
    }
    .file{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
  }