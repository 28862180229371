.container{
    width: 100vw;
    min-height: 100vh;
    overflow: auto;
}
.header{
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* main과 맞출 것! */
    height: 5vh;
    background: linear-gradient(45deg, rgb(192, 216, 255), rgb(135, 206, 250));
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
}
.back{
    height: 80%;
    margin-right: 10px;
    background: linear-gradient(45deg, rgb(192, 216, 255), rgb(135, 206, 250));
    border: solid;
    border-color: aliceblue;
    border-radius: 5px;
    color: white;
}
.title{
    padding-left:5vw;
}
.hambuger{
    color:white;
    background:transparent;
    border:none;
    font-size:25px;
    font-weight:bolder;
}
.headerButtonContainer{
    padding-right: 3vw;
    height: 100%;
    
}

.facker{
    height: 5vh;
}
.main{
    /* header와 맞출 것! */
    min-height: 95vh;
}


  

.userButton{
    justify-self: flex-end;
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.2s ease;

}
