.container{
    display: flex;
    height: 7vh;
    align-items: center;
    background-color: aliceblue;
    border-bottom: solid 1px rgb(192,216,255);
    border-radius: 5px;
    transition: background-color 0.2s ease;
}

.container:active{
    background-color: rgb(223, 233, 241);
}
.icon{
    padding-left: 2vw;
    padding-right: 4vw;
    max-width: 50px;
}
.name{
    font-weight: bold;
}