.container{
    width: 100vw;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-left: 40px;
    padding-right: 40px;
}
.titleContianer{
    align-self: flex-start;
}
.buttonContainer{
    align-self: flex-start;
    margin-bottom: 10px;
}
.customUploadButton {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    height: 10%;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
}
.customUploadButton:hover, .customFileUpload button:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
.table {
width: 100%;
border-top: 1px solid #d3d3d3;
border-collapse: collapse;
}
.table tr{
    display: flex;
}
.no{flex: 0.5;}
.fileName{flex: 5;}
.uploader{flex: 1;}
.time{flex: 1;}
.downloadCount{flex: 1;}
.download{flex: 1;}
.table thead tr th { 
    background-color: rgb(192, 216, 255);
    border-top: 3px solid rgb(139, 184, 255);
    color: white;
    white-space: nowrap;
}
.table thead tr th,.table tbody tr td {
border-bottom: 1px solid #d3d3d3;
padding: 10px;
}
.delete{background-color: transparent; border: none;}
.left {text-align: left; align-content: center;}
.right {  float: right; align-content: center;}
.center { text-align: center;align-content: center;}
.uploaderData{
    transition: scale 0.2s ease;
    cursor: pointer;
}
.uploaderData:hover{
    scale: 1.07;
}
.timeData{
    text-align: center;
    font-size: 10px;
    align-content: center;
}
.downloadButton{
    cursor: pointer;
    white-space: nowrap;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease;
    text-align: center;
}
.downloadButton:hover, .uploadButton:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
.uploadInput{
    margin-left: 20px;
    height: 30px;
    padding-left: 10px;
    border-color: #bdbdbd;
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    width: 200px;
    transition: scale 0.3s ease;
}
.uploadInput:focus{
    outline: none;
    scale: 1.03;
}
.uploadInput:hover{
    scale: 1.03;
}
.uploadButton{
    margin-left: 10px;
    cursor: pointer;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease;
    text-align: center;
}
/* 프로그래스 바 컨테이너 */
.progressBar {
    margin-left: 20px;
    width: 150px;
    height: 25px;
    border-radius: 4px; /* 더 부드러운 모서리 */
    overflow: hidden;
    position: relative;
  }
  
  /* 진행률 */
  .progress {
    height: 100%;
    background: linear-gradient(90deg, #4facfe, #a0faff); /* 그라데이션 색상 */
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    transition: width 0.4s ease; /* 전환 속도를 살짝 더 빠르게 */
  }
  .progressText{
    position: relative;
    left: 45%;
    color: #000000; /* 텍스트 색상을 흰색으로 */
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); /* 텍스트에 살짝 그림자 */
  }