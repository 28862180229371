.writePostButton{
    position: fixed;
    bottom: 40px;
    left: 90%;
}

.writePostButton button{
    padding: 0.7rem 1.5rem;
    max-height: 55px;
    border-radius: 8px;
    /* text-align: center; */
    border: none;
    background-color: rgb(161, 197, 255);
    background-color: rgba(161, 197, 255, 0.395);
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
    letter-spacing: 2px;
    
}

.writePostButton button:hover{
    
    background-color: rgba(139, 183, 255, 0.676);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);

}
/* 모바일 용 */
@media screen and (max-width: 768px) {
    .writePostButton{
        position: static;
    }
    .writePostButton button{
        margin-top: 3vh;
        padding: 0.7rem 1.5rem;
        max-height: 55px;
        background-color: rgb(161, 197, 255);
        z-index: 1;
    }
  }