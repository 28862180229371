.container{
    display: flex;
    height: 7vh;
    align-items: center;
    background-color: aliceblue;
    border-bottom: solid 1px rgb(192,216,255);
    border-radius: 5px;
    transition: background-color 0.2s ease;

}
.container:active{
    background-color: rgb(223, 233, 241);
}
.icon{
    padding-left: 2vw;
    padding-right: 4vw;
}
.name{
    font-weight: bold;
}
  .menuContainer{
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .menu{
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  .menu li{
    padding: 5px 10px;
    cursor: pointer;
  }
  .menu li:hover{
    background-color: aliceblue;
  }