.fileDetailMenu {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 35%;
    width: 100vw;
    background: linear-gradient(180deg, rgb(192, 218, 255), rgb(135, 206, 250)); 
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
    transition: bottom 0.4s ease-in-out;
}

.closeFileDetailMenu {
    border: none;
    height: 10%;
    background: linear-gradient(90deg, rgb(156, 197, 255), rgb(135, 206, 250));
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.downImg {
    width: 24px;
    height: 24px;
}

.fileDetail {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.fileImg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 150px;
    max-width: 150px;
    border-radius: 12px;
    object-fit: cover;
}

.inforContainer {
    color: rgb(37, 37, 37);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 10px 15px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inforContainer div {
    font-size: 15px;
    color: #333;
    line-height: 1.5;
}

.inforContainer div b {
    font-size: 16px;
    color: #555;
}
.buttonContainer{
    display: flex;
}
.download{
    border-color: rgb(179, 203, 236);
    border-style: solid;
    width: 100vw;
    height: 5vh;
    background-color: rgb(192, 218, 255);
    color: white;
    font-size: 1em;

}

