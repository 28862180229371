.mainContainer{
    display: flex;
    flex-direction: row;
    min-width: 80vw;
    overflow: auto;
    justify-content: space-between;
    padding-left: 40px;
    min-height: 100vh;
    overflow: auto; 
}

.buttonContainer button{
    cursor: pointer;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease;
    text-align: center;
}
.buttonContainer button:hover, .group:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
.content{
    margin-top: 2em;
    min-width: 100%;
    min-height: 10em;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}
.group{
    cursor: pointer;
    width: 25%;
    height: 10em;
    color: white;
    border-radius: 10px;
    background-color: rgb(192, 216, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}
.groupDate{
    font-size: 0.8em;
    padding-right: 5%;
    width: 95%;
    text-align: right;
}
.hr{
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 80vw;
    height: 4px;
    background-color: rgb(139, 184, 255);
    border-radius: 1.5px;
  }
.makeGroupText{
    padding-top: 10%;
    width: 100%;
    text-align: center;
    font-size: 2em;
}
@media screen and (max-width: 768px) {
    .mainContainer{
        min-width: 95vw;
        min-height: 95vh;
        padding-top: 5vh;
        padding-left: 5vw;
    }
    .hr{min-width: 90vw;}
    .group{
        width: 90vw;
        height: 8em;
    }

  }