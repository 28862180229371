.container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
}
.mainContainer{
    display: flex;
    flex-direction: row;
    min-width: 80vw;
    overflow: auto;
    justify-content: space-between;
    padding-left: 40px;
  }
  .inputModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .customFileUpload{
    width: 100%;
  }
  .customUploadButton {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-align: center;
  }
  .customUploadButton:hover, .customFileUpload button:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
  }
  .customFileUpload button{
    margin-left: 20px;
    cursor: pointer;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-align: center;
    border: none;
  }
  
  input[type="file"] {
    position: relative;
    width: 0px;
    opacity: 0;
    cursor: pointer;
  }
  .hr{
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 80vw;
    height: 4px;
    background-color: rgb(139, 184, 255);
    border-radius: 1.5px;
  }

  .foldersContainer{
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    margin-bottom: 20px;
  }
  .filesContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:10px;
  }
  .menuContainer{
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .menu{
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  .menu li{
    padding: 5px 10px;
    cursor: pointer;
  }
  .menu li:hover{
    background-color: aliceblue;
  }

  .groupMemberContainer{
    position: fixed;
    background-color: rgb(139, 184, 255);
    transition: all 0.2s ease-out;
  }

  @media screen and (max-width: 768px) {
    .container{
      padding-top: 5vh;
      min-height: 95vh;
    }
    .customUploadButton {
      padding: 5px 10px;
      font-size: 0.8em;
    }
    .customFileUpload button{
      margin-left: 5px;
      padding: 5px 10px;
      font-size: 0.7em;
    }

  }