.upload{
  position: fixed;
  bottom: 5%;
  right: 5%;
  background: linear-gradient(90deg,rgb(240, 230, 255), rgb(192, 216, 255), rgb(192, 216, 255));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.15);

  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text{
  color: white;
  font-size: 3em;
}
.uploadButtonContainer{
  display: flex;
  flex-direction: column;
}
.fileUploadButton, .folderUploadButton {
  white-space: nowrap;
text-overflow: ellipsis;
  background: linear-gradient(90deg, rgb(190, 196, 254), rgba(192, 216, 255, 1), rgba(192, 216, 255, 0.8));
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25), 0 8px 30px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  width: 50vw;
  padding: 12px 20px;
  border-radius: 15px;
  transition: width 0.5s ease;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.fileUploadButton{
  text-align: center;
}
.folderUploadButton{
  border: none;
  font-weight: bold;
}
.uploadMenuOpen{
  position:fixed;
  transition: right 0.3s ease;
}