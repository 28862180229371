.afterFileDetail{
    min-height: 100%;
    min-width: 300px;
    background: rgb(225, 232, 239);
    box-shadow: -1px 0px 9px 0px rgb(187, 187, 187);
  }
  .closeButton{
    margin-left: 5%;
    margin-top: 5%;
    cursor: pointer;
    padding: 10px 15px;
    background-color: rgb(192, 216, 255);
    color: rgb(66, 66, 66);
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-align: center;
    border: none;
  }
  .fileDetail{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .closeButton:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
  }
  .fileDetail img{
    margin-top: 50px;
    padding: 20px;
    background-color: rgb(212, 228, 254);
    border-radius: 10px;
    transition: scale 0.3s ease;
  }
  .fileDetail img:hover{
    scale: 1.05;
  }
  .detailButtonContainer{
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .detailButtonContainer button{
    margin-top: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 20px;
    background-color: rgb(192, 216, 255);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-align: center;
    border: none;
  }
  .detailButtonContainer button:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
  }
  .table{
    margin-top: 50px;
    text-align: left;
  }
  .table tbody tr th{
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .table tbody tr td{
    padding-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    .afterFileDetail{
      position: fixed;
      width: 100%;
      min-height: 100%;
      background: rgb(225, 232, 239);
      box-shadow: -1px 0px 9px 0px rgb(187, 187, 187);
      z-index: 1000;
    }
  }