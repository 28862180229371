.sidebarContainer{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #edf2ff;
    width: 250px;
    padding-top: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
}
.faker{
    position: relative;
    background-color: red;
    left: 0;
    min-width: 250px;
    height: 100%;
    justify-self: flex-start;
    align-self: center;
}
.logout{
    background-color: rgb(192, 216, 255);
    border-radius: 8px;
    margin: 10%;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}
.logout:hover{
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
.title{
    font-size: 35px;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    align-self: center;
}
.list{
    /* padding-left: 10%; */
    padding-top: 10%;
    cursor: pointer;

}
.list li {
    padding-left: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: 20px;
    transition: all 0.3s ease;
}
.list li:hover {
    /* background-color: rgb(213, 221, 236); */
    border-radius: 20px;
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);
}
