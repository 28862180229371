.forumDetail {
    opacity: 0;
    align-items: left;
    background-color: aliceblue;
    width: 100%;
    min-height: 95vh;

    margin-right: 10rem;
    margin-left: 10rem;
    transition: all 0.8s ease-out;
    transform: translateX(20%); /* 처음 위치는 화면 밖 오른쪽 */
    padding-top: 5vh;
}

.sliding {
    transform: translateX(0); /* 페이지가 오른쪽에서 왼쪽으로 슬라이드 */
    opacity: 1; /* 페이지가 보이게 됨 */
}


.title{
    position: relative;
    padding-bottom: 0.5em;
    margin-bottom: 10px;

}

.title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(127, 176, 255, 1), rgba(127, 176, 255, 0));
}

.container{
    margin-top: 4em;
    margin-left: 2em;
    margin-right: 2em;
}


.containerHeader{
    color: rgb(127, 176, 255);
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.containerContent{
    font-size: 16px;
    min-height: 18em;
}
.containerContent img{
    max-width: 600px;
}

.containerWrite{
    display: flex;
    margin: 1.5rem 0;
    padding: 1.5rem 2rem;
    background-color: rgb(219, 233, 255);
    border-radius: 10px;
    min-height: 50px;
    min-width: 500px;
    /* align-items: center; */
    justify-content: space-between;
    
}
.attachmentContainer{
    position: relative;
    display: flex;
    gap: 10px;
    white-space: nowrap;
}
.attachmentContainer article{
    position: absolute;
    top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color:rgb(108, 108, 255);
    cursor: pointer;
    background-color: rgb(219, 233, 255);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 1px rgb(166, 180, 202);
}
.file:hover{
    color: rgb(60, 60, 150);
}
.containerWrite textarea{
    resize: none;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    font-family: 'Pretendard-Regular';
    font-size: 16px;
    width: 80%;
    padding: 0.5em;
    padding-bottom: 0em;
    outline-color: transparent;
    transition: outline-color 0.5s ease;
    
}


.containerWrite textarea:focus {
    outline-color: rgb(161, 197, 255);
}

.containerWrite button{
    padding: 1rem 1.5rem;
    max-height: 55px;
    border-radius: 8px;
    /* text-align: center; */
    border: none;
    background-color: rgb(161, 197, 255);
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
    
}

.containerWrite button:hover{
    
    background-color: rgb(139, 184, 255);
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-10%);

}



.containerFooter{
    display: flex;
    justify-content: flex-end;
    color: rgb(127, 176, 255);
    /* border-bottom: 4px solid rgba(127, 176, 255, 0.8); */
    padding-bottom: 0.5em;

}


.comment{
    position: relative;
    margin-left: 1em;
    padding-bottom: 1em;
    /* border-bottom: 1px dashed rgba(127, 176, 255, 0.8); */
    margin-bottom: 1.2em;
    padding-bottom: 1em;
}


.comment:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(127, 176, 255, 0.6), rgba(127, 176, 255, 0));
}

.comment:last-child {
    border-bottom: none; /* 마지막 댓글의 구분선 제거 */
}

.commentHeader{
    display: flex;
    color: rgb(127, 176, 255);
    margin-bottom: 0.7em;
    font-size: 15px;
    
}

.commentContent{
    padding-left: 0.2em;
}

.deleteBtn{
    padding-top: 0.1em;
    margin-left: 0.5em;
    opacity: 0.4;
    transition: all 0.2s ease;

    
}

.deleteBtn:hover{
    opacity: 0.8;
}

@media screen and (max-width: 768px) {
    .forumDetail {
        opacity: 1;
        min-width: 100vw;
        min-height: 100vh;
        overflow: auto;
        margin-right: 0;
        margin-left: 0;
        transform: none; 
        padding-top: 1rem;
        padding-bottom: 3rem;
    }
    .pageTitle{
        display: none;
    }
    .containerContent img{
        max-width: 80vw;
    }
    .containerContent pre{
        font-size: 14px;
    }
    .containerComment{
        max-width: 100vw;
    }
    .containerWrite{
        min-width: 0;
        max-width: 90vw;
        padding: 1rem;
    }
    .containerWrite button{
        padding: 0;
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 10px;
    }
    .containerWrite textarea{
        min-width: none;
    }
    .attachmentContainer article{
        right: 20px;
    }
  }