.sideMenu{
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    top: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(192, 218, 255, 1), rgba(230, 240, 255, 1), rgba(160, 200, 255, 1));
    background: linear-gradient(180deg, rgba(192, 218, 255, 1), rgba(160, 200, 255, 1), rgba(120, 180, 240, 1));

    color: white;
    height: 100%;
    width: 60%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 100;
    transition: right 0.5s ease-out;
}
.sideMenu ol{
    list-style: none;
    align-self: flex-start;
}
.sideMenu ol li{
    font-size: 1em;
    display: flex;
    align-items: center;
    height: 10vh;
    transition: scale 0.2s ease;
}
.sideMenu ol li:active{
    scale: 1.05;
}
.userButton{
    position: fixed;
    bottom: 30px;
    justify-self: flex-end;
}
.userButton button{
    border: none;
    background-color: transparent;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}